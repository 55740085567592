import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";
import { useColorMode } from "theme-ui";
import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  if(isDark){
    return (
      <LogoContainer>
        <img src="https://gamesinfo.s3.eu-central-1.amazonaws.com/dark_logo.png" style={{maxWidth: '200px'}} className="Logo__Desktop"></img>
        <img src="https://gamesinfo.s3.eu-central-1.amazonaws.com/dark_logo.png" style={{maxWidth: '200px'}} className="Logo__Mobile"></img>
      </LogoContainer>
    );
  } else {
    return (
      <LogoContainer>
        <img src="https://gamesinfo.s3.eu-central-1.amazonaws.com/output-onlinepngtools.png" style={{maxWidth: '200px'}} className="Logo__Desktop"></img>
        <img src="https://gamesinfo.s3.eu-central-1.amazonaws.com/output-onlinepngtools.png" style={{maxWidth: '200px'}} className="Logo__Mobile"></img>
      </LogoContainer>
    );
  }
  
};




export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
      
    }
    
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
